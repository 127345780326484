import { inject, Injectable, Signal, signal } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, takeUntil, tap } from 'rxjs/operators';

import { ProjectHealth, ProjectListDetailDto, ProjectsApiService } from '@api-open';
import { AlertifyService } from '@tk/shared/lib';

@Injectable()
export class ProjectDtoService {
  private _projectDto = signal<ProjectListDetailDto | null>(null);

  private readonly projectReset = new Subject<void>();

  private readonly projectsApiService = inject(ProjectsApiService);

  get projectDto(): Signal<ProjectListDetailDto | null> {
    return this._projectDto;
  }

  presetProject(dto: ProjectListDetailDto): void {
    this._projectDto.set(dto);
  }

  // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
  reloadProject(): void {
    this.fetchProject(this._projectDto()!.id!)
      .pipe(takeUntil(this.projectReset.asObservable()))
      .subscribe((project) => {
        this._projectDto.set(project);
      });
  }

  resetProjectData(): void {
    this._projectDto.set(null);
    this.projectReset.next();
  }

  isVirtualCluster(projectId: number): Observable<boolean> {
    if (!this._projectDto() || projectId !== this._projectDto()?.id) {
      return this.fetchProject(projectId).pipe(
        tap((project) => {
          if (project) {
            this._projectDto.set(project);
          } else {
            throw new Error(`Project not found: ${projectId}`);
          }
        }),
        filter((project): project is ProjectListDetailDto => project !== null),
        map(({ isVirtualCluster }) => isVirtualCluster!),
        catchError((err) => {
          inject(AlertifyService).negative(err);
          throw err;
        }),
      );
    }

    // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
    return of(this._projectDto()!.isVirtualCluster!);
  }

  hasKubeInfo(projectId: number): Observable<boolean> {
    return this.fetchProject(projectId).pipe(
      tap((project) => {
        if (project) {
          this._projectDto.set(project);
        } else {
          throw new Error(`Project not found: ${projectId}`);
        }
      }),
      map((projectData) => {
        if (
          projectData &&
          projectData.hasKubeConfigFile &&
          (projectData.health === ProjectHealth.healthy || projectData.health === ProjectHealth.warning)
        ) {
          return true;
        }
        return false;
      }),
      catchError((err) => {
        inject(AlertifyService).negative(err);
        throw err;
      }),
    );
  }

  isProjectExists(projectId: number): Observable<boolean> {
    if (!this._projectDto() || projectId !== this._projectDto()?.id) {
      return this.fetchProject(projectId).pipe(
        tap((project) => {
          if (project) {
            this._projectDto.set(project);
          } else {
            throw new Error(`Project not found: ${projectId}`);
          }
        }),
        map((project) => !!project),
        catchError((err) => {
          inject(AlertifyService).negative(err);
          throw err;
        }),
      );
    }
    return of(true);
  }

  // TODO: When strictNullChecks is enabled, replace non-null assertions with proper null checks
  private fetchProject(projectId: number): Observable<ProjectListDetailDto | null> {
    return this.projectsApiService.projectsList({ id: projectId }).pipe(
      map((responce) => {
        const { data } = responce;
        if (data && data.length > 0) {
          const [projectDto] = data;
          return projectDto;
        }
        return null;
      }),
    );
  }
}
